import React from "react";
import styles from './Trustpilot.module.scss'
import Script from "next/script";
import { connect } from "react-redux";

function TrustPilot({dark}) {
    return (
        <div className={`page-content__block ${styles['trust-pilot']}`}>
            <Script
                id='trustpilot-script'
                type="text/javascript"
                src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                strategy='afterInteractive'
            />
            <div className={`page-content__block__container-centered ${styles['trust-pilot__container']}`}>
                <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="5419b6a8b0d04a076446a9ad"
                    data-businessunit-id="60377af63217ab0001f8db89"
                    data-style-height="24px"
                    data-style-width="100%"
                    data-theme={dark ? "dark" : "light"}
                    data-min-review-count="0"
                    data-style-alignment="center"
                >
                    <a href="https://www.trustpilot.com/review/try.direct" target="_blank" rel="noopener">
                        Trustpilot
                    </a>
                </div>
            </div>
        </div>
    )
}

const mapStatToProps = state => ({
    dark: state.UI.theme.dark,
})

export default connect(mapStatToProps)(TrustPilot)