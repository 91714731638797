import React from 'react'
import * as config from '../../config';
import ImgCDN from "../../components/ImgCDN";
import RippleLayout from "../../components/ripple/RippleLayout";
import styles from './Partners.module.scss'
import { Block, Content } from "../../layouts/Content";

const PARTNERS_DATA = [
    {
        name: 'UPCLOUD',
        link: config.PARTNER_LINK_UPCLOUD,
        image: '/img/partners/upcloud.png'
    }, {
        name: 'SCALEWAY',
        link: config.PARTNER_LINK_SCALEWAY,
        image: '/img/partners/scaleway.png'
    }, {
        name: 'GUVER',
        link: config.PARTNER_LINK_GUVER,
        image: '/img/partners/guver.png'
    }, {
        name: 'FreeLinuxBlog',
        link: config.PARTNER_LINK_FREELINUXBLOG,
        image: '/img/partners/freelinuxblog.png'
    }
]

function Partners() {
    const renderPartners = () => {
        return PARTNERS_DATA.map((item, index) => {
            let {name, link, image} = item;
            return (
                <li className={styles['partner']} key={index}>
                    <a
                        className={styles['partner__link']}
                        href={link}
                        target="_blank"
                        draggable='false'
                        rel='nofollow'
                    >
                        <RippleLayout
                            className={styles['partner__link__button']}
                            visuals={{
                                height: '100%',
                                width: '100%',
                                borderRadius: '15px',
                                colorBasic: ['var(--colorFontInfo)']
                            }}
                        >
                            <div className={styles['partner__link__button__logo']}>
                                <ImgCDN
                                    className={styles['partner__link__button__logo__image']}
                                    src={image}
                                    alt={name}
                                    draggable='false'
                                />
                            </div>
                        </RippleLayout>
                    </a>
                </li>
            )
        })
    };

    return (
        <Block className={`${styles['partners']}`}>
            <Content className={`${styles['partners__container']}`}>
                <div className={styles["partners__container__partners"]}>
                    <h2 className={styles["partners__container__partners__title"]}>Partners</h2>
                    <ul className={styles["partners__container__partners__list"]}>
                        {renderPartners()}
                    </ul>
                </div>
            </Content>
        </Block>
    )
}

export default Partners