import React from "react";
import PropTypes from 'prop-types';

export default function AspectRatioBox({ratio, className, children, padding, debug}) {

    const verticalPadding = ratio[1] / ratio[0] * 100;

    return (
        <div
            className={`ar-box${debug ? ` ar-box-debug` : ''}${className ? ` ${className}` : ''}`}
            style={{
                '--ar-box-padding': padding,
                '--verticalPadding': verticalPadding + '%',
            }}
        >
            <div className='ar-box__container'>
                <div className='ar-box__container__content'>{children}</div>
            </div>
        </div>
    )
}

AspectRatioBox.propTypes = {
    ratio: (props, propName, componentName) => {
        if (
            !props[propName]?.length
            || !Array.isArray(props[propName])
            || props[propName]?.length !== 2
            || props[propName]?.some(v => typeof v !== 'number')
        ) {
            return new Error(
                `Invalid "${propName}" value supplied to ${componentName}. "${propName}" supports only an array with two numbers.`
            )
        }
    },
    className: PropTypes.string,
    padding: PropTypes.string,
    debug: PropTypes.bool,
}