import React from 'react';
import styles from './SupportProviders.module.scss'
import { NAV_CLOUDS } from "../../config";
import Link from "next/link";
import Icon from "../../components/Icon";
import { Block, Content } from "../../layouts/Content";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SupportProviders = ({clouds}) => {

    const showDebugInfo = cloud => {

        if (!cloud.lock && !!cloud.impl) return null;

        return (
            <div className={styles['cloud__container__status']}>
                {!cloud.lock && !cloud.impl && <FontAwesomeIcon icon={'fas fa-angle-double-up'}/>}
                {!!cloud.lock && <FontAwesomeIcon icon='fas fa-eye-slash'/>}
            </div>
        )
    }

    const renderCloud = cloud => {
        return (
            <li className={`${styles['cloud']}`} key={cloud.code}>
                <Link
                    prefetch={false}
                    href={NAV_CLOUDS}
                    className={styles['cloud__container']}
                    draggable='false'
                >

                    <div className={styles['cloud__container__content']}>
                        <div
                            className={
                                styles['cloud__container__content__image-icon']
                                + ' ' + styles[`cloud__container__content__img-${cloud.code}`]
                            }
                        >
                            <Icon
                                icon={cloud.icon}
                                alt={`${cloud.name} logo image`}
                                draggable='false'
                            />
                        </div>
                        <h3 className={styles['cloud__container__content__name']}>{cloud.name}</h3>
                    </div>
                    {showDebugInfo(cloud)}

                </Link>
            </li>
        );
    }

    return (
        <Block className={`${styles['clouds']}`}>
            <Content className={`${styles['clouds__container']}`}>
                <div className={styles["clouds__container__content"]}>
                    <h2>Supported Cloud Platforms</h2>
                    <ul
                        className={styles['clouds__container__content__pane']}
                        style={{
                            '--provider-quantity': (clouds?.length <= 6 && clouds?.length >= 4) ? clouds?.length : null,
                        }}
                    >
                        {clouds.map(renderCloud)}
                    </ul>
                </div>
            </Content>
        </Block>
    );
};