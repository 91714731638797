import React from "react";
import { connect } from 'react-redux';
import ImgCDN from "./ImgCDN";

function Icon({
                  icon, height, width, className, alt, dark, fallbackImages, draggable, optimize, style, fill, sizes
}) {

    const shouldUseDark = !!dark && !!icon?.dark?.image;

    const src = shouldUseDark ? icon?.dark?.image : icon?.light?.image;
    let none = `/images/no-icon${dark ? '-dark' : ''}.svg`;
    let dimensions = {
        width: shouldUseDark ? icon?.dark?.width : icon?.light?.width || undefined,
        height: shouldUseDark ? icon?.dark?.height : icon?.light?.height || undefined,
    }

    if (!!fallbackImages?.length) {
        none = !dark ? fallbackImages[0] : !!fallbackImages[1] ? fallbackImages[1] : fallbackImages[0]
    }

    const isAsset = !!dark && !!icon?.dark?.isAsset || !dark && !!icon?.light?.isAsset;

    if (src) {
        return (
            <ImgCDN
                style={style}
                className={className}
                src={!isAsset ? `/files/${src}` : src}
                alt={alt || undefined}
                draggable={draggable}
                fill={fill}
                // height={height}
                // width={width}
                height={fill ? undefined : height || dimensions.height}
                width={fill ? undefined : width || dimensions.width}
                optimize={(!!dimensions.height && !!dimensions.width || height && width) && !!optimize || fill}
                sizes={sizes}
            />
        )
    } else {
        // return null
        return (
            <img
                style={style}
                className={className}
                src={none}
                alt={alt || undefined}
                draggable={draggable}
                height={height}
                width={width}
            />
        )
    }
}

const mapStateToProps = state => ({
    dark: state.UI.theme.dark,
});

export default connect(mapStateToProps)(Icon)