import React from 'react'
import Button from "../../components/ripple/Button";
import Link from "next/link";
import { Block, Content } from "../../layouts/Content";
import styles from './Promo.module.scss';
import Icon from "../../components/Icon";
import { URL_PICTURES_FULL } from "../../config";
import DraftHTML from "../../components/draftEditor/export/DraftHTML";

export function RenderPromo({promo}) {
    return (
        <Block
            className={styles['promo']}
            style={{'--arrival-bg-image': `url("${URL_PICTURES_FULL}/${promo.image.image}")`}}
        >
            <div className={styles['promo__background']}/>
            <Content className={styles['promo__container']}>
                <div className={styles['promo__container__text-container']}>
                    <div className={styles['promo__container__text-container__sticker']}>
                        <div className={styles['promo__container__text-container__sticker__back']}>
                            <div className={styles['promo__container__text-container__sticker__back__content']}>
                                New Arrival!
                            </div>
                        </div>
                    </div>
                    <div className={styles['promo__container__text-container__content']}>
                        <div className={styles.head}>
                            <div className={styles.head__icon}>
                                <Icon
                                    className={styles.head__icon__image}
                                    icon={promo.icon}
                                    alt={`${promo.title} icon`}
                                    height={promo.icon.light.height}
                                    width={promo.icon.light.width}
                                />
                            </div>
                            <h2>{promo.title}</h2>
                        </div>
                        <DraftHTML
                            className={`content-editor`}
                            content={promo.content}
                        />
                        <div className={styles['promo__container__text-container__content__button-container']}>
                            <Link
                                prefetch={false}
                                href={promo.link}
                                className={styles['promo__container__text-container__content__button-container__link']}
                                target='_blank'
                            >
                                <Button
                                    displayType='text'
                                    iconRight='fas fa-arrow-right'
                                    // title='Learn more about this stack'
                                    title={promo.linkText}
                                    visuals={{colorBasic: ['var(--colorFontInfo)']}}
                                    tabIndex={-1}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </Content>
        </Block>
    );
}