import React from "react";
import Head from "next/head";
import { DEBUG, DOMAIN_BASE, NAME_BASE, URL_BASE } from "../../config";
import { DESCRIPTION_DEFAULT } from "../templates";
import { useRouter } from "next/router";
import { getImageURL } from "../common";

export default function SeoApiHandler({data}) {

    const router = useRouter();
    const defaultImage = URL_BASE + "/images/trydirect-social.png";
    const defaultTwitterImage = URL_BASE + "/images/trydirect-social-twitter.png";
    const imageFile = data?.open_graph?.og_image?.image;
    const imageTwitterFile = data?.open_graph?.og_image_twitter?.image;
    const image = imageFile && getImageURL(imageFile);
    const imageTwitter = imageTwitterFile ? getImageURL(imageTwitterFile) : image && image;

    const tags = {
        title: data.seo_title,
        description: data.seo_description,
        OG: {
            title: data?.open_graph?.og_title || data.seo_title || DOMAIN_BASE,
            description: data?.open_graph?.og_description || data.seo_description || DESCRIPTION_DEFAULT,
            image: image || defaultImage,
            imageTwitter: imageTwitter || defaultTwitterImage,
        },
        noindex: 'noindex' in data ? data.noindex : true,
    };

    return (
        <Head>
            {
                tags.title
                    ? <title key='title'>{`${tags.title} | ${NAME_BASE}`}</title>
                    : <title key='title'>{`${DEBUG ? 'Undefined title | ' : ''}${NAME_BASE}`}</title>
            }
            {!!tags.description && <meta name="description" content={tags.description} key='description'/>}
            {
                (!!DEBUG || !!tags.noindex)
                    ? <meta name="robots" content="noindex, nofollow, noarchive, nocache, noimageindex" key='robots'/>
                    : <meta name="robots" content="" key='robots'/>
            }

            <meta property='og:title' content={tags.OG.title} key='og:title'/>
            <meta property='og:description' content={tags.OG.description} key='og:description'/>
            <meta property='og:site_name' content={NAME_BASE} key='og:site_name'/>
            <meta property='og:url' content={URL_BASE + router.asPath} key='og:url'/>
            <meta property="og:image" content={tags.OG.image} key='og:image'/>
            <meta property="og:type" content={tags.OG.type || 'website'} key='og:type'/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta property="twitter:domain" content={DOMAIN_BASE}/>
            <meta property="twitter:url" content={URL_BASE + router.asPath}/>
            <meta name="twitter:title" content={tags.OG.title || tags.title || DOMAIN_BASE}/>
            <meta
                name="twitter:description"
                content={tags.OG.description || tags.description || DESCRIPTION_DEFAULT}
            />
            <meta name="twitter:image" content={tags.OG.imageTwitter || tags.OG.image}/>
        </Head>
    )
}